<template>
  <div>
    {{ notificationModel }}
    <v-breadcrumbs :items="crumbs"></v-breadcrumbs>
    <base-loader :isLoading="isLoading"></base-loader>
    <v-row>
      <v-col cols="12" sm="12" md="12">
        <v-card>
          <v-card-text>
            <h4 class="text-md-h4 primary--text mb-5">
              {{ `${isEdit ? "Edit" : "New"} Notification` }}
            </h4>
            <v-form ref="notificationForm" @submit.prevent="save()">
              <v-row>
                <v-col md="6">
                  {{ notificationModel.description }}
                  <v-text-field
                    v-model="notificationModel.description"
                    label="Description"
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col md="6">
                  <base-basic-user-picker
                    :chosen-basic-user.sync="notificationModel.assignedTo"
                    :initial="notificationModel.assignedTo"
                    label="Employee"
                    outlined
                  />
                </v-col>
                {{ notificationModel.employerAssignedTo }}
                <v-col>
                  <base-basic-employer-picker
                    :chosen-user.sync="notificationModel.employerAssignedTo"
                    :initial="notificationModel.employerAssignedTo"
                    label="Employer"
                    outlined
                  />
                </v-col>
              </v-row>
              <!-- {{ this.notification.employerAssignedTo }} -->
              <v-btn type="submit" color="success">Save</v-btn>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { gql } from "apollo-boost";
import { Notification } from "@/models/Notification";

export default {
  name: "EditNotifications",
  data() {
    return {
      isEdit: false,
      isLoading: false,
      notificationModel: new Notification(),
      me: "",
    };
  },
  created() {
    this.isEdit = this.$route.params.id != 0;
    this.isLoading = this.isEdit ? true : false;
  },
  computed: {
    crumbs() {
      return [
        {
          text: "Notifications",
          disabled: false,
          href: "/admin/notifications",
        },
        {
          text: "Notification Detail",
          disabled: false,
          href: `/admin/notification/${this.$route.params.id}`,
        },
        {
          text: `${this.isEdit ? "Edit" : "New"} Notification`,
          disabled: true,
        },
      ];
    },
  },

  apollo: {
    me: gql`
      query {
        me {
          id
          firstName
          lastName
        }
      }
    `,
    notification: {
      query: gql`
        query Notification($id: ID!) {
          notification(id: $id) {
            id
            description
            assignedTo
            assignedToName
            read
            deleted
            createdBy
            createdByName
            updatedBy
            updatedByName
            employerAssignedTo
            employerAssignedToName
            # employerNumber
            deleted
            deletedAt
            createdAt
            updatedAt
          }
        }
      `,
      fetchPolicy: "network-only",
      skip() {
        return !this.isEdit;
      },
      variables() {
        const self = this;
        return {
          id: self.$route.params.id,
        };
      },
      result(response, key) {
        this.isLoading = false;
        this.notificationModel.id = this.notification.id;
        this.notificationModel.deleted = this.notification.deleted;
        this.notificationModel.assignedTo = this.notification.assignedTo;
        this.notificationModel.assignedToName =
          this.notification.assignedToName;
        this.notificationModel.description = this.notification.description;
        this.notificationModel.createdBy = self.me.id;
        this.notificationModel.createdByName = this.notification.createdByName;
        this.notificationModel.updatedBy = self.me.id;
        this.notificationModel.updatedByName = this.notification.updatedByName;
        this.notificationModel.read = this.notification.read;
        this.notificationModel.employerAssignedTo =
          this.notification.employerAssignedTo;
        this.notificationModel.employerAssignedToName =
          this.notification.employerAssignedToName;
        // this.notificationModel.employerNumber =this.notification.employerNumber;
      },
    },
  },
  methods: {
    async save() {
      this.isLoading = true;
      const self = this;
      if (!self.$refs.notificationForm.validate()) {
        self.$swal("Error", "Validation failed", "error");
        self.isLoading = false;
        return;
      }
      if (self.isEdit) {
        await this.updateNotification()
          .then(() => {
            this.$swal(
              "Success!",
              "Notification was successfully updated",
              "success"
            );
            window.location.href = `/admin/notifications`;
          })
          .catch((e) => {
            this.$swal(
              "Error!",
              "something went wrong when trying to update the notification, check data and try again later!",
              "error"
            );
          });
      } else {
        await this.createNotification()
          .then(() => {
            this.$swal(
              "Success!",
              "Notification was successfully created",
              "success"
            );
            window.location.href = `/admin/notifications`;
          })
          .catch((e) => {
            this.$swal(
              "Error!",
              `Something went wrong when creating the notification, check data and try again later!`,
              "error"
            );
          });
      }
      this.isLoading = false;
    },
    async updateNotification() {
      const self = this;
      try {
        const response = await self.$apollo.mutate({
          mutation: gql`
            mutation update($notification: NotificationUpdateInput!) {
              updateNotification(notification: $notification) {
                id
                updated
              }
            }
          `,
          variables: {
            notification: {
              id: this.notification.id,
              updatedBy: self.me.id,
              updatedByName: this.notification.updatedByName,
              // employerNumber: self.notification.employerNumber,
              // updatedByName: self.notificationModel.updatedByName,
              assignedTo: self.notificationModel.assignedTo,
              employerAssignedTo: self.notificationModel.employerAssignedTo,
              employerAssignedToName:
                self.notificationModel.employerAssignedToName,
              read: self.notificationModel.read,
              deleted: self.notificationModel.deleted,
              description: self.notificationModel.description,
            },
          },
        });
        if (!response) {
          throw "Something went wrong when creating the notification!";
        }
        if (response.errors && response.errors.length > 0) {
          throw response.errors[0];
        }
      } catch (e) {
        throw e.message;
      }
    },
    async createNotification() {
      const self = this;
      try {
        console.log("attempting to create notification");
        const response = await self.$apollo.mutate({
          mutation: gql`
            mutation createNotification(
              $notification: NotificationCreateInput!
            ) {
              createNotification(notification: $notification) {
                id
              }
            }
          `,
          variables: {
            notification: {
              description: self.notificationModel.description,
              createdBy: self.me.id,
              createdByName: this.notificationModel.createdByName,
              read: false,
              deleted: false,
              assignedTo: self.notificationModel.assignedTo,
              employerAssignedTo: self.notificationModel.employerAssignedTo,
              employerAssignedToName:
                self.notificationModel.employerAssignedToName,
              // employerNumber: self.notification.employerNumber,
            },
          },
        });

        if (!response) {
          console.log("Notification could'nt reach create query");
          throw "Something went wrong when creating the Notification!";
        }
        if (response.errors && response.errors.length > 0) {
          throw response.errors[0];
        }
        if (response && response.data.createNotification) {
          self.notificationModel.id = response?.data?.createNotification?.id;
        }
      } catch (e) {
        throw e.message;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
